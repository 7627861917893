import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";
import { stringToSlug } from "../../utils/stringManipulation";

const query = graphql`
  query {
    allStrapiProcedure(filter: { intervento_padre: { id: { eq: null } } }) {
      nodes {
        id
        strapiId
        nome
        tipologia
        icona {
          localFile {
            absolutePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        copertina {
          localFile {
            absolutePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        descrizione
        intervento_padre {
          nome
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative">
        <div className="sterea-center-column h-1/4-screen z-20 flex justify-center items-center">
          <h1 className="w-1/2 text-sterea-blue text-center text-3xl lg:text-4xl leading-normal font-bold">
            Gli interventi
          </h1>
        </div>
      </div>

      {/* <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column flex flex-wrap justify-center py-12 px-min -mb-3">
          <div className="sterea-blue-button mr-3 mb-3">
            <Link to="/blog/chirurgia-estetica">
              Chirurgia estetica
            </Link>
          </div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Trapianto capelli</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Fertilità</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Odontoiatria</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mr-3 mb-3">Turismo medico</div>
          <div className="sterea-button bg-sterea-light-gray filter drop-shadow-sm mb-3">Generale</div>
        </div>
      </div> */}

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min py-12">
          <div className="flex flex-wrap justify-center">
            {data.allStrapiProcedure.nodes.map((intervento) => (
              <Link
                to={`/interventi/${stringToSlug(intervento.nome)}`}
                className="sterea-procedure-card-wrapper"
              >
                <div className="sterea-procedure-card">
                  {intervento?.icona && intervento?.icona?.localFile &&
                    (intervento?.icona?.localFile?.extension === "svg" ? (
                      <img
                        src={intervento?.icona?.localFile?.publicURL}
                        className="mb-6 h-32"
                        alt={`Icona che rappresenta un intervento di ${intervento.nome}`}
                      />
                    ) : (
                      <GatsbyImage
                        image={getImage(intervento?.icona?.localFile)}
                        className="h-96"
                        objectFit="cover"
                        alt={`Icona che rappresenta un intervento di ${intervento.nome}`}
                      />
                    ))}
                  <h4 className="mt-6 font-bold text-center">
                    {intervento.nome}
                  </h4>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
